.editor {
  font: 15px 'Inconsolata', 'Courier', monospace;
  background-color: #FFFFFF;
  /*max-width: 1400px;*/
  height: 730px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.editor .code {
  width: 100%;
  /*background-color: #eff5dc;*/
  line-height: 1.4;
  display: block;
  padding:2rem 0 0 0;
  margin-left: 10%;
  overflow: hidden;
  order: 2;
}

@media only screen and (max-width: 1300px){
  .editor .code{
    padding: 0;
  }
}


.editor .code .menu {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 4px 0 rgba(83, 93, 104, 0.20), 0 -3ex 0 0 rgba(71, 127, 194, 0.1);
  padding: 10px 0;
  margin: 3ex 0 0 -1.5em;
  overflow-y: scroll;
  max-height: 500px;
  z-index: 500;
}

.editor .code .menu:before{
  content: '123';
  display: block;
  height: 3ex;
  position: relative;
  margin-top: -3ex;
  top: -10px;
  background: rgba(71, 127, 194, 0.1);
  pointer-events: none;
}

.editor .code .menu li {
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-content: stretch;
}

.editor .code .menu li:hover, .editor .code .menu li.checked:hover {
  /*background-color: rgba(71, 127, 194, 0.1);*/
}

.editor .code .menu li.checked {
  background: transparent url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAsMTAgQzAsMTAuOSAxLjEsMTEuMyAxLjcsMTAuNyBMMTAuNywxLjcgQzExLjEsMS4zIDExLjEsMC43IDEwLjcsMC4zIEMxMC4zLC0wLjEgOS43LC0wLjEgOS4zLDAuMyBMMiw3LjUgTDIsNSBDMiw0LjQgMS41LDQgMSw0IEMwLjQ1LDQgMCw0LjQ1IDAsNSBMMCwxMCBaIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+PC9zdmc+')
  no-repeat 0.8em center;
  font-weight: bold;
}

.editor .menu li.checked .btn-link{
  max-width: 415px;
}

/*.editor a {*/
/*color: #4780C2;*/
/*text-decoration: none;*/
/*}*/

.editor .menu .btn-link{
  color: #000;
  padding: 0.4ex 2em 0.4ex 2em;
  flex-grow: 1;
  flex-basis: auto;
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  /*margin-right: 28px;*/
}

.editor .menu .btn-link:not(:last-child){
  padding-right: 0.5em;
}

.editor .menu .btn-link.add{
  padding: 0.4ex 0;
  flex-grow: 0;
  flex-basis: 12%;
  min-width: 38px;
  max-width: 38px;
  visibility: hidden;
}

.editor .menu li:hover .btn-link:hover, .editor .menu li.checked:hover .btn-link:hover {
  background-color: rgba(71, 127, 194, 0.08);
}

.editor .menu li:hover .btn-link.add, .editor .menu li.checked:hover .btn-link.add {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUsMSBDNSwwLjQ0NzcxNTI1IDUuNDQ3NzE1MjUsMCA2LDAgQzYuNTUyMjg0NzUsMCA3LDAuNDQ3NzE1MjUgNywxIEw3LDUgTDExLDUgQzExLjU1MjI4NDcsNSAxMiw1LjQ0NzcxNTI1IDEyLDYgQzEyLDYuNTUyMjg0NzUgMTEuNTUyMjg0Nyw3IDExLDcgTDcsNyBMNywxMSBDNywxMS41NTIyODQ3IDYuNTUyMjg0NzUsMTIgNiwxMiBDNS40NDc3MTUyNSwxMiA1LDExLjU1MjI4NDcgNSwxMSBMNSw3IEwxLDcgQzAuNDQ3NzE1MjUsNyAzLjEzMDgyODkzZS0xNCw2LjU1MjI4NDc1IDAsNiBDLTMuMTMwODI4OTNlLTE0LDUuNDQ3NzE1MjUgMC40NDc3MTUyNSw1IDEsNSBMNSw1IEw1LDEgWiIgZmlsbD0iIzAwMDAwMCI+PC9wYXRoPjwvc3ZnPg==')
  no-repeat center center;
  visibility: visible;
}

.editor .menu li:hover .btn-link.add:hover, .editor .menu li.checked:hover .btn-link.add:hover {
  background-color: rgba(71, 127, 194, 0.08);
}

.editor .menu li:hover, .editor .menu li.checked:hover{
  background-color: rgba(71, 127, 194, 0.08);
}

.editor .menu .add:before, .editor .menu .add:after{
  content: '';
}

.editor .fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 250;
}

.editor dl {
  margin: 0;
  padding: 0;
}

.editor dd, dt {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
}

.editor dl:not(.plugins) dd:hover {
  background-color: rgba(71, 127, 194, 0.1);
}

.editor p {
  margin: 0;
  padding: 0;
}

.editor ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.editor .code dl.data dd, .editor .code dd.null {
  margin-left: 1em
}

.editor .code dd.string {
  margin-left: 0.5em
}

.editor .code dd.array {
  margin-left: 0
}

.editor .code dl {
  margin-left: 1em;
  white-space: nowrap;
}

.editor .code dt{
  width: 4.5em;
}

.editor .code ul {
  display: inline-block;
}

.editor .code li {
  margin-left: 1em;
}

.editor .code li.disabled {
  color: rgba(0, 0, 0, 0.3);
  margin-left: 1em;
}

.editor .code li.disabled .btn-link {
  color: rgba(0, 0, 0, 0.3);
}

.editor .code li.disabled:before {
  content: '//';
  margin-left: -1em;
}

.editor .code dd p:first-child {
  margin-left: 4.5em
}

.editor .code dd p:last-child {
  /*margin-left: -0.5em;*/
}

.editor .code .plugins dt {
  width: 0;
}

.editor .code .plugins .btn-link:hover {
  background-color: rgba(71, 127, 194, 0.1);
}

.editor .code .plugins li.disabled .btn-link:hover {
  background-color: rgba(128, 128, 128, 0.1);
}

.editor .chart {
  width: 100%;
  background-color: #FFFFFF;
  padding: 2em 3rem 3em 4%;
  height: calc(100% - 6em);
  order: 1;
}

.editor .error {
  position: absolute;
  background-color: rgb(255, 143, 113);
  display: none;
  padding: 1em;
  width: calc(75% - 4em);
  max-width: calc(1050px - 4em);
}

.editor .error.show{
  display: block;
}

.editor .controls .controls-place {
  background: #eff5dc;
  transition: top .3s ease;
  top:0;
}
.editor .controls .controls-place.shifted-down {
  top:60px
}
.editor .navigator button {
  cursor: pointer;
}

.editor .navigator button:focus {
  outline: 0;
}



.editor .navigator button:hover {
  /*background-color: rgba(71, 127, 194, 0.2);*/
}

.editor .code span.syn{
  font-style: italic;
}

.editor .code span.string{
  color: #4da771;
}

.btn-link {
  text-align: left;
  margin: 0;
  transition: color .2s, border-color .2s;
  color: #05aced;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font: 15px 'Inconsolata', 'Courier', monospace;
  background: transparent;
  padding: 0;
}